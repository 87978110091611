// icon:cloud | Icomoon https://icomoon.io/ | Keyamoon
import * as React from "react";
import { Row } from "react-bootstrap";
import { Container } from 'react-bootstrap'
import { Col } from 'react-bootstrap'

/**
 * svg from https://reactsvgicons.com/
 * 
 * @param {boolean} hit true if clicked
 * @param {object} onClick handler  
 * @returns 
 */
function CloudIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={props.hit ? '#f00' : '#000'}
      height="5em"
      width="5em"
      onClick={props.onClick}
      {...props}
    >
      <path
        fill={props.hit ? '#f00' : '#000'}
        d="M16 10.274a2.726 2.726 0 00-2.078-2.648A3.72 3.72 0 0010.205 4a3.712 3.712 0 00-2.92 1.418 2.09 2.09 0 00-3.719 1.573 3.028 3.028 0 00-3.567 2.98A3.028 3.028 0 003.026 13H13.28a2.725 2.725 0 002.719-2.726z"
      />
    </svg>
  );
}

/**
 * Show message if clouds are 'hit'
 * 
 * @prop {boolean} isHit true if cloud clicked
 * @prop {string} status displayed to user
 * @returns 
 */
function HitMessage(props) {
  const isHit = props.isHit;
  const status = props.status;
  if (isHit) {
    return (
      <div className="alert alert-info mb-0">{status}</div>
    );
  }
  return <div className="alert alert-info invisible p-1 mt-4 mb-4"></div>;
}

/**
 * Render the cloud group and maintain state.
 */
class CloudGroup extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      clouds: Array(3).fill(false),
    };
  }

  handleClick(i) {
    const clouds = this.state.clouds.slice();
    clouds[i] = !clouds[i];
    this.setState({
      clouds: clouds,
    });
  }

  renderCloud(i) {
    return (
      <CloudIcon
        hit={this.state.clouds[i] ? 1 : 0}
        onClick={() => this.handleClick(i)}
      />
    );
  }

  render() {
    const hits = calculateHits(this.state.clouds);
    let status;
    let showAlert = false;
    if (hits > 0) {
      status = 'Clouds cannoned: ' + hits;
      showAlert = true;
    }

    return (
      <div>
        <Container>
          <Row>
            <Col className="text-center">{this.renderCloud(0)}</Col>
            <Col className="text-center">{this.renderCloud(1)}</Col>
            <Col className="text-center">{this.renderCloud(2)}</Col>
          </Row>
          <Row>
            <HitMessage
              isHit={showAlert}
              status={status}
            />
          </Row>
        </Container>
      </div>
    );
  }
}

/**
 * 
 * @param {Array} clouds status of the clouds 
 * @returns {integer} number of clouds clicked
 */
function calculateHits(clouds) {
  let hits = 0;
  for (let i = 0; i < clouds.length; i++) {
      if(clouds[i]){
          hits++;
      }
  }
  return hits;
}


export default CloudGroup;
