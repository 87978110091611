import * as React from "react"
import Layout from '../components/layout'
import CloudGroup from "../components/cloud";

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <h2 className="border-bottom mt-3">Trivia</h2>
      <p>Did you know clouds and cannons have been enemies since before time began?</p>
      <h2 className="border-bottom mt-3">Target practice</h2>
      <p>Click on the clouds!</p>
      <CloudGroup />
      <h2 className="border-bottom mt-3">Sponsors</h2>
      <p>A word from our sponsors</p>
      <p>Mah nizzle nizzle leo. Phasellizzle fo, da bomb sizzle vehicula yo, velit erat convallizzle quam, vel elementum sapizzle check out this uhuh ... yih! the bizzle. Vivamizzle yo mamma gangsta. Etizzle shizzlin dizzle tempizzle magna. Crizzle boofron yippiyo break it down. Vestibulizzle that's the shizzle, rhoncizzle a, lacinia izzle, fo shizzle izzle, urna. Vivamizzle eleifend euismizzle fo shizzle mah nizzle fo rizzle, mah home g-dizzle. Maecenizzle tincidunt gravida dope. Shizzle my nizzle crocodizzle yo mamma tortizzle egizzle erizzle ultricies pulvinizzle. Dawg mollizzle sodalizzle erizzle. Shizznit egestizzle, metus a dizzle sollicitudizzle, gangster rizzle aliquet yo, izzle elementizzle uhuh ... yih! purizzle yo mamma velizzle. Things tristique enim quis dope. Sed izzle ligula go to hizzle dui crackalackin euismizzle. Phasellizzle izzle lorem. Bling bling gangster. Bling bling ac nibh.</p>
    </Layout>
  );
}

export default IndexPage
